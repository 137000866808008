import { ref, watch, computed } from '@vue/composition-api'
import moment from 'moment'

import store from '@/store'

import useToast from '@useToast'

export default function useScheduleList() {
  // Use toast
  const { toastError } = useToast()

  const refScheduleListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'employee', key: 'employee', sortable: true },
    { label: 'type', key: 'type', sortable: true },
    { label: 'scheduleCalendarTimes', key: 'scheduleCalendarTimes', sortable: true },
    {
      label: 'employeePhoneNumber',
      key: 'employeePhoneNumber',
      sortable: false,
    },
  ]
  const perPage = ref(100)
  const totalSchedules = ref(0)
  const currentPage = ref(1)
  const sortBy = ref('employee')
  const perPageOptions = [10, 25, 50, 100]
  const isSortDirDesc = ref(true)
  const employeeType = computed(
    () => store.getters['app-work-schedule/getEmployeeType'],
  )
  const workDate = computed(
    () => store.getters['app-work-schedule/getWorkDate'],
  )
  const scheduleType = computed(
    () => store.getters['app-work-schedule/getScheduleType'],
  )

  const isToday = computed(() => store.getters['app-work-schedule/getWorkDate'] === moment().format('YYYY-MM-DD'))

  const loading = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refScheduleListTable.value
      ? refScheduleListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSchedules.value,
    }
  })

  const refetchData = () => {
    refScheduleListTable.value.refresh()
  }

  watch(
    [currentPage, perPage, employeeType, scheduleType, sortBy, isSortDirDesc],
    () => {
      refetchData()
    },
  )
  const fetchSchedules = () => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-work-schedule/fetchSchedules', {
        size: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value === 'scheduleCalendarTimes' ? 'scheduleCalendars' : (sortBy.value === 'type' ? 'scheduleTags' : 'employee'),
        sortDirection: isSortDirDesc.value ? 'ASC' : 'DESC',
        employeeType: employeeType.value ? 'SE' : 'BE',
        startDate: workDate.value,
        endDate: workDate.value,
        isOnline: isToday.value ? !scheduleType.value : undefined,
        isParent: true,
      })
      .then(response => {
        totalSchedules.value = response.data.total
        const schedules = response.data
        resolve(schedules.items)
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.workSchedule.fetchScheduleError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  return {
    fetchSchedules,

    tableColumns,
    perPage,
    currentPage,
    totalSchedules,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refetchData,
    refScheduleListTable,
    loading,

    employeeType,
    scheduleType,
    workDate,
  }
}
